import React from 'react';
import './Header.css';
import { Text, HStack } from '@chakra-ui/react';

const Header = () => {
  return (
    <HStack className="breathing-exercise-screen-header">
      <Text>Emergency</Text>
    </HStack>
  );
};

export default Header;
