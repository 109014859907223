import React, { useState, useMemo } from 'react';
import './BeathingExericesScreen.css';
import { Box, Text, Stack, Image, useToast } from '@chakra-ui/react';
import FingertPrint from '../../components/finger-print/FingertPrint';
import Header from '../../components/header/Header';

const QuestionContainer = ({ header, question, answers }) => {
  return (
    <Box className="question-container">
      <Box className="question-container-header">
        <Text as="b">{header}</Text>
        <Text>{question}</Text>
      </Box>
      <Box className="question-container-body">
        {answers.map((a, i) => (
          <Box className="question-container-answer" key={i}>
            <Text
              // className="question-container-answer-text"
              onClick={() => a.onClick()}
            >
              {a.text}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const FirstQuestion = ({ setStep }) => {
  const header = 'Share';
  const question =
    'Would you like to let someone now you have activated your helmet?';
  const answers = [
    {
      text: 'Yes',
      onClick: () => setStep(2),
    },
    {
      text: 'Ask me later',
      onClick: () => setStep(3),
    },
    {
      text: 'No',
      onClick: () => setStep(3),
    },
  ];
  return (
    <QuestionContainer header={header} question={question} answers={answers} />
  );
};

const SecondQuestion = ({ setStep }) => {
  const toast = useToast();
  const header = 'Share';
  const question = 'Who would you like to share with?';
  const answers = [
    {
      text: 'Mum',
      onClick: () => {
        toast({
          // title: '',
          description: 'Sending notification to Mum',
          status: 'success',
          duration: 2000,
          onCloseComplete: () => setStep(3),
          // isClosable: true,
        });
      },
    },

    {
      text: 'Zoe',
      onClick: () => {
        toast({
          // title: '',
          description: 'Sending notification to Zoe',
          status: 'success',
          duration: 2000,
          onCloseComplete: () => setStep(3),
          // isClosable: true,
        });
      },
    },

    {
      text: 'Back',
      onClick: () => {
        setStep(3);
      },
    },
  ];
  return (
    <QuestionContainer header={header} question={question} answers={answers} />
  );
};

const ThirdQuestion = ({ setStep, setCurrentPage }) => {
  const header = 'Continue';
  const question = 'Continue in your helmet?';
  const answers = [
    {
      text: 'Yes',
      onClick: () => setStep(0),
    },

    {
      text: 'No',
      onClick: () => {
        setCurrentPage(0);
        setStep(0);
      },
    },
  ];
  return (
    <QuestionContainer header={header} question={question} answers={answers} />
  );
};

const BeathingExericesScreen = ({ setCurrentPage }) => {
  const [step, setStep] = useState(0);

  const currentStepElement = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <Image
            src="image-placeholder.png"
            className="image-placeholder-container"
          />
        );
      case 1:
        return <FirstQuestion setStep={setStep} />;
      case 2:
        return <SecondQuestion setStep={setStep} />;
      case 3:
        return (
          <ThirdQuestion setStep={setStep} setCurrentPage={setCurrentPage} />
        );
      default:
        return 'DEFAILT';
    }
  }, [setCurrentPage, step]);

  return (
    <Box className="breathing-exercise-screen">
      <Header />

      <Box className="breathing-exercise-screen-content-container">
        {currentStepElement}

        <Stack className="finger-print-container">
          <FingertPrint
            onLongPress={() => {
              console.log('LONG PRESSSSS');
              setStep(1);
            }}
          />
          <Text>Hold to stop Helmet</Text>
        </Stack>
      </Box>
    </Box>
  );
};

export default BeathingExericesScreen;
