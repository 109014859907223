import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import LoginScreen from './pages/login-screen/LoginScreen';
import HomeScreen from './pages/home-screen/HomeScreen';
import BeathingExericesScreen from './pages/breathing-exercise-screen/BeathingExericesScreen';
import { useMemo, useState } from 'react';

function App() {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem('authToken') ?? '',
  );
  const [currentPage, setCurrentPage] = useState(0);

  const currentPageElement = useMemo(() => {
    switch (currentPage) {
      case 0:
        return <HomeScreen setCurrentPage={setCurrentPage} />;
      case 1:
        return <BeathingExericesScreen setCurrentPage={setCurrentPage} />;
      default:
        return <HomeScreen setCurrentPage={setCurrentPage} />;
    }
  }, [currentPage]);

  return (
    <ChakraProvider>
      <div className="App">
        {!authToken ? (
          <LoginScreen setAuthToken={setAuthToken} />
        ) : (
          currentPageElement
        )}
      </div>
    </ChakraProvider>
  );
}

export default App;
