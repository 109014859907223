import React from 'react';
import './HomeScreen.css';
import { Box, Text, Stack, HStack } from '@chakra-ui/react';
import { AiTwotoneSetting } from 'react-icons/ai';
import FingertPrint from '../../components/finger-print/FingertPrint';

const HomeScreen = ({ setCurrentPage }) => {
  return (
    <Box className="home-screen">
      <HStack className="home-screen-header">
        <Text>Helmet</Text>
        <AiTwotoneSetting className="header-icon" />
      </HStack>

      <Stack className="finger-print-container">
        <FingertPrint
          onLongPress={() => {
            console.log('LONG PRESSSSS');
            setCurrentPage(1);
          }}
        />
        <Text>Hold to activate Helmet</Text>
      </Stack>
    </Box>
  );
};

export default HomeScreen;
