import './FingerPrint.css';
import React, { useState, useRef } from 'react';
import { FaFingerprint } from 'react-icons/fa';

const FingertPrint = ({ onLongPress }) => {
  const [presssing, setPressinsg] = useState(false);
  const timerRef = useRef();

  const handleOnMouseDown = (e) => {
    console.log('handleOnMouseDown');
    // e.preventDefault();
    startPressTimer();
    setPressinsg(true);
  };
  const handleOnTouchStart = (e) => {
    console.log('handleOnTouchStart');
    // e.preventDefault();
    startPressTimer();
    setPressinsg(true);
  };
  const handleOnMouseUp = (e) => {
    console.log('handleOnMouseUp');
    e.preventDefault();
    clearTimeout(timerRef.current);
    setPressinsg(false);
  };
  const handleOnTouchEnd = (e) => {
    console.log('handleOnTouchEnd');
    e.preventDefault();
    clearTimeout(timerRef.current);
    setPressinsg(false);
  };

  const startPressTimer = () => {
    timerRef.current = setTimeout(() => {
      onLongPress();
    }, 2000);
  };

  return (
    <FaFingerprint
      className={`finger-print ${presssing ? 'finger-print-animation' : ''}`}
      onMouseDown={handleOnMouseDown}
      onTouchStart={handleOnTouchStart}
      onMouseUp={handleOnMouseUp}
      onTouchEnd={handleOnTouchEnd}
    />
  );
};

export default FingertPrint;
