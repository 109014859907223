import React, { useState } from 'react';
import './LoginScreen.css';
import { Box, Text, Input, Stack, Button } from '@chakra-ui/react';

const LoginScreen = ({ setAuthToken }) => {
  const [email, setEmail] = useState('user@helmet.app');
  const [password, setPassword] = useState('');

  return (
    <Box>
      <Box className="grayBox" />
      <Text className="header">Welcome to Helmet</Text>

      <Box className="form">
        <Stack className="formField">
          <Text>Email</Text>
          <Input
            value={email}
            onChange={(a) => setEmail(a.target.value)}
            placeholder="Your Email"
            type="email"
          />
        </Stack>
        <Stack className="formField">
          <Text>Password</Text>
          <Input
            value={password}
            onChange={(a) => setPassword(a.target.value)}
            placeholder="Your Password"
            type="password"
            // size='sm'
          />
        </Stack>
        <Button
          className="loginButton"
          isDisabled={!email || !password}
          onClick={() => {
            const authToken = Date.now();
            localStorage.setItem('authToken', authToken);
            setAuthToken(authToken);
          }}
        >
          Login
        </Button>

        <Box className="links">
          <Text className="link">Forgot password?</Text>
          <Text className="link">Privacy</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginScreen;
